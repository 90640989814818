var render = function () {
  var _vm$$v;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "width": "30px",
      "min-width": "30px",
      "height": "30px",
      "margin": "0",
      "padding": "0"
    },
    on: {
      "click": function click($event) {
        _vm.isOpenModalBackToPreview = true;
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('c-text', {
    attrs: {
      "color": "neutral.superDarkGray",
      "font-size": "22px",
      "font-weight": "600"
    }
  }, [_vm._v(" Target & Saran ")])], 1), _c('c-flex', {
    attrs: {
      "margin-top": "24px",
      "justify-content": "flex-end",
      "gap": "16px",
      "margin-bottom": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": _vm.buttonSave.variant,
      "border-radius": "40px",
      "variant-color": "primary",
      "is-disabled": _vm.isSaveDisabled,
      "is-loading": _vm.isLoadingSave
    },
    on: {
      "click": _vm.onSaveDraft
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonSave.label) + " ")])], 1), _c('NutritionalProfileSteps', {
    attrs: {
      "draft": _vm.draft,
      "target-and-suggestions": (_vm$$v = _vm.$v) !== null && _vm$$v !== void 0 && _vm$$v.$invalid ? -1 : 1
    }
  }), _c('c-box', [_c('FormInput', {
    attrs: {
      "type": "textarea",
      "label": "Diagnosis Gizi Primer",
      "placeholder": "Masukkan diagnosis gizi primer",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.primaryNutritionDiagnosis),
      "invalid-text": "Wajib diisi"
    },
    on: {
      "blur": _vm.$v.form.primaryNutritionDiagnosis.$touch
    },
    model: {
      value: _vm.form.primaryNutritionDiagnosis,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "primaryNutritionDiagnosis", $$v);
      },
      expression: "form.primaryNutritionDiagnosis"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "label": "Diagnosis Gizi Sekunder",
      "placeholder": "Masukkan diagnosis gizi sekunder",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.secondaryNutritionDiagnosis),
      "invalid-text": "Wajib diisi"
    },
    on: {
      "blur": _vm.$v.form.secondaryNutritionDiagnosis.$touch
    },
    model: {
      value: _vm.form.secondaryNutritionDiagnosis,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "secondaryNutritionDiagnosis", $$v);
      },
      expression: "form.secondaryNutritionDiagnosis"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "label": "Intervensi Gizi",
      "placeholder": "Masukkan intervensi gizi",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.nutritionalIntervention),
      "invalid-text": "Wajib diisi"
    },
    on: {
      "blur": _vm.$v.form.nutritionalIntervention.$touch
    },
    model: {
      value: _vm.form.nutritionalIntervention,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nutritionalIntervention", $$v);
      },
      expression: "form.nutritionalIntervention"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "label": "Tujuan Meal Plan",
      "placeholder": "Masukkan tujuan mealplan",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.mealPlanGoals),
      "invalid-text": "Wajib diisi"
    },
    on: {
      "blur": _vm.$v.form.mealPlanGoals.$touch
    },
    model: {
      value: _vm.form.mealPlanGoals,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mealPlanGoals", $$v);
      },
      expression: "form.mealPlanGoals"
    }
  }), _c('TestFieldArray', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var push = _ref.push,
            remove = _ref.remove;
        return [_vm._l(_vm.form.thingsThatNeedToBeMaintained, function (_, index) {
          return _c('c-flex', {
            key: index,
            attrs: {
              "align-items": "center",
              "gap": "8px"
            }
          }, [_c('FormInput', {
            attrs: {
              "type": "textarea",
              "label": "Hal yang Perlu Dipertahankan ".concat(index === 0 ? '' : index + 1).trim(),
              "placeholder": "Masukkan hal yang perlu dipertahankan ".concat(index === 0 ? '' : index + 1).trim(),
              "full-width": "",
              "is-required": "",
              "is-invalid": _vm.isInvalidField(_vm.$v.form.thingsThatNeedToBeMaintained.$each[index]),
              "invalid-text": "Wajib diisi"
            },
            on: {
              "blur": _vm.$v.form.thingsThatNeedToBeMaintained.$each[index].$touch
            },
            model: {
              value: _vm.form.thingsThatNeedToBeMaintained[index],
              callback: function callback($$v) {
                _vm.$set(_vm.form.thingsThatNeedToBeMaintained, index, $$v);
              },
              expression: "form.thingsThatNeedToBeMaintained[index]"
            }
          }), index !== 0 ? _c('c-button', {
            attrs: {
              "background-color": "transparent",
              "height": "50px",
              "width": "50px"
            },
            on: {
              "click": function click($event) {
                return remove(_vm.form.thingsThatNeedToBeMaintained, index);
              }
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icons/icon-trash.svg'),
              "height": "38px",
              "width": "38px",
              "fill": "#D32737"
            }
          })], 1) : _vm._e()], 1);
        }), _c('c-flex', [_c('BaseButton', {
          attrs: {
            "margin": "auto",
            "size": "small",
            "border-radius": "1000px",
            "variant": "outlined",
            "width": "80%",
            "right-svg-icon": require('@/assets/icons/icon-circle-plus.svg'),
            "right-svg-icon-color": "#008C81"
          },
          on: {
            "click": function click() {
              push(_vm.form.thingsThatNeedToBeMaintained, '');

              _vm.$v.form.thingsThatNeedToBeMaintained.$touch();
            }
          }
        }, [_vm._v(" Tambah ")])], 1)];
      }
    }])
  }), _c('TestFieldArray', {
    attrs: {
      "margin-top": "16px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var push = _ref2.push,
            remove = _ref2.remove;
        return [_vm._l(_vm.form.thingsThatNeedToBeImproved, function (_, index) {
          return _c('c-flex', {
            key: index,
            attrs: {
              "align-items": "center",
              "gap": "8px"
            }
          }, [_c('FormInput', {
            attrs: {
              "type": "textarea",
              "label": "Hal yang Perlu diperbaiki ".concat(index === 0 ? '' : index + 1).trim(),
              "placeholder": "Masukkan hal yang perlu diperbaiki ".concat(index === 0 ? '' : index + 1).trim(),
              "full-width": "",
              "is-required": "",
              "is-invalid": _vm.isInvalidField(_vm.$v.form.thingsThatNeedToBeImproved.$each[index]),
              "invalid-text": "Wajib diisi"
            },
            on: {
              "blur": _vm.$v.form.thingsThatNeedToBeImproved.$each[index].$touch
            },
            model: {
              value: _vm.form.thingsThatNeedToBeImproved[index],
              callback: function callback($$v) {
                _vm.$set(_vm.form.thingsThatNeedToBeImproved, index, $$v);
              },
              expression: "form.thingsThatNeedToBeImproved[index]"
            }
          }), index !== 0 ? _c('c-button', {
            attrs: {
              "background-color": "transparent",
              "height": "50px",
              "width": "50px"
            },
            on: {
              "click": function click($event) {
                return remove(_vm.form.thingsThatNeedToBeImproved, index);
              }
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icons/icon-trash.svg'),
              "height": "38px",
              "width": "38px",
              "fill": "#D32737"
            }
          })], 1) : _vm._e()], 1);
        }), _c('c-flex', [_c('BaseButton', {
          attrs: {
            "margin": "auto",
            "size": "small",
            "border-radius": "1000px",
            "variant": "outlined",
            "width": "80%",
            "right-svg-icon": require('@/assets/icons/icon-circle-plus.svg'),
            "right-svg-icon-color": "#008C81"
          },
          on: {
            "click": function click() {
              push(_vm.form.thingsThatNeedToBeImproved, '');

              _vm.$v.form.thingsThatNeedToBeImproved.$touch();
            }
          }
        }, [_vm._v(" Tambah ")])], 1)];
      }
    }])
  }), _c('TestFieldArray', {
    attrs: {
      "margin-top": "16px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var push = _ref3.push,
            remove = _ref3.remove;
        return [_vm._l(_vm.form.targetForThisPeriod, function (_, index) {
          return _c('c-flex', {
            key: index,
            attrs: {
              "align-items": "center",
              "gap": "8px"
            }
          }, [_c('FormInput', {
            attrs: {
              "type": "textarea",
              "label": "Target Periode Ini ".concat(index + 1).trim(),
              "placeholder": "Masukkan target periode ini ".concat(index + 1).trim(),
              "full-width": "",
              "is-required": "",
              "is-invalid": _vm.isInvalidField(_vm.$v.form.targetForThisPeriod.$each[index]),
              "invalid-text": "Wajib diisi"
            },
            on: {
              "blur": _vm.$v.form.targetForThisPeriod.$each[index].$touch
            },
            model: {
              value: _vm.form.targetForThisPeriod[index],
              callback: function callback($$v) {
                _vm.$set(_vm.form.targetForThisPeriod, index, $$v);
              },
              expression: "form.targetForThisPeriod[index]"
            }
          }), ![0, 1].includes(index) ? _c('c-button', {
            attrs: {
              "background-color": "transparent",
              "height": "50px",
              "width": "50px"
            },
            on: {
              "click": function click($event) {
                return remove(_vm.form.targetForThisPeriod, index);
              }
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icons/icon-trash.svg'),
              "height": "38px",
              "width": "38px",
              "fill": "#D32737"
            }
          })], 1) : _vm._e()], 1);
        }), _c('c-flex', [_c('BaseButton', {
          attrs: {
            "margin": "auto",
            "size": "small",
            "border-radius": "1000px",
            "variant": "outlined",
            "width": "80%",
            "right-svg-icon": require('@/assets/icons/icon-circle-plus.svg'),
            "right-svg-icon-color": "#008C81"
          },
          on: {
            "click": function click() {
              push(_vm.form.targetForThisPeriod, '');

              _vm.$v.form.targetForThisPeriod.$touch();
            }
          }
        }, [_vm._v(" Tambah ")])], 1)];
      }
    }])
  }), _c('TestFieldArray', {
    attrs: {
      "margin-top": "16px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var push = _ref4.push,
            remove = _ref4.remove;
        return [_vm._l(_vm.form.nutritionAndLifestyleAdvice, function (_, index) {
          return _c('c-flex', {
            key: index,
            attrs: {
              "align-items": "center",
              "gap": "8px"
            }
          }, [_c('FormInput', {
            attrs: {
              "type": "textarea",
              "label": "Saran Gizi dan Gaya Hidup ".concat(index + 1).trim(),
              "placeholder": "Masukkan saran gizi dan gaya hidup ".concat(index + 1).trim(),
              "full-width": "",
              "is-required": "",
              "is-invalid": _vm.isInvalidField(_vm.$v.form.nutritionAndLifestyleAdvice.$each[index]),
              "invalid-text": "Wajib diisi"
            },
            on: {
              "blur": _vm.$v.form.nutritionAndLifestyleAdvice.$each[index].$touch
            },
            model: {
              value: _vm.form.nutritionAndLifestyleAdvice[index],
              callback: function callback($$v) {
                _vm.$set(_vm.form.nutritionAndLifestyleAdvice, index, $$v);
              },
              expression: "form.nutritionAndLifestyleAdvice[index]"
            }
          }), ![0, 1].includes(index) ? _c('c-button', {
            attrs: {
              "background-color": "transparent",
              "height": "50px",
              "width": "50px"
            },
            on: {
              "click": function click($event) {
                return remove(_vm.form.nutritionAndLifestyleAdvice, index);
              }
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icons/icon-trash.svg'),
              "height": "38px",
              "width": "38px",
              "fill": "#D32737"
            }
          })], 1) : _vm._e()], 1);
        }), _c('c-flex', [_c('BaseButton', {
          attrs: {
            "margin": "auto",
            "size": "small",
            "border-radius": "1000px",
            "variant": "outlined",
            "width": "80%",
            "right-svg-icon": require('@/assets/icons/icon-circle-plus.svg'),
            "right-svg-icon-color": "#008C81"
          },
          on: {
            "click": function click() {
              push(_vm.form.nutritionAndLifestyleAdvice, '');

              _vm.$v.form.nutritionAndLifestyleAdvice.$touch();
            }
          }
        }, [_vm._v(" Tambah ")])], 1)];
      }
    }])
  }), _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(2, 1fr)'],
      "gap": ['16px'],
      "margin-top": "24px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "height": "60px",
      "border-radius": "60px",
      "font-size": "18px",
      "is-disabled": _vm.isLoadingSave
    },
    on: {
      "click": _vm.onOpenModalCancel
    }
  }, [_vm._v(" Sebelumnya ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "height": "60px",
      "border-radius": "60px",
      "font-size": "18px",
      "is-disabled": _vm.isLoadingSave,
      "is-loading": _vm.isLoadingSave
    },
    on: {
      "click": _vm.onSaveDraft
    }
  }, [_vm._v(" Simpan ")])], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalCancel,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 16px 20px 16px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400",
            "margin-bottom": "8px"
          }
        }, [_vm._v(" Data kamu belum lengkap ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "12px",
            "size-desktop": "14px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman sebelumnya? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": _vm.onCloseModalCancel
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.onConfirmModalCancel
          }
        }, [_vm._v(" Ya ")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalNext,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 16px 20px 16px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400",
            "margin-bottom": "8px"
          }
        }, [_vm._v(" Data kamu belum lengkap ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "12px",
            "size-desktop": "14px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman selanjutnya? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": _vm.onCancelNext
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.onNext
          }
        }, [_vm._v(" Ya ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSuccessSaveData', {
    attrs: {
      "is-open": _vm.isOpenModalSuccesSaveData
    },
    on: {
      "close": function close($event) {
        _vm.isOpenModalSuccesSaveData = false;
      }
    }
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalBackToPreview,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px 20px 24px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah anda yakin"), _c('br'), _vm._v("ingin membatalkan pengisian formulir? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalBackToPreview = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.handleBackToPreview
          }
        }, [_vm._v(" Konfirmasi ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }