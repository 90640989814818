<template>
  <c-box
    margin-top="24px"
  >
    <Tabs
      active-tab="profile"
      mode="edit"
      :is-program-consultation-basic="isProgramConsultationBasic"
      :tabs="_tabsRealTime"
    >
      <c-box
        v-if="!isLoadingDraft"
      >
        <FormProfile
          v-if="$route.query?.tab === constants.tabs['nutritional-condition']"
          ref="formProfile"
          :draft="draft"
          :is-draft-exist="!!draft"
          @update:validation="onChangeValidation"
        />

        <FormIntake
          v-else-if="$route.query?.tab === constants.tabs['nutritional-intake']"
          ref="formIntake"
          :data-preview="dataPreview"
          :draft="draft"
          :is-draft-exist="!!draft"
          @update:validation="onChangeValidation"
        />

        <FormBodyConditions
          v-else-if="$route.query?.tab === constants.tabs['body-condition']"
          ref="formConditions"
          :draft="draft"
          :is-draft-exist="!!draft"
          @update:validation="onChangeValidation"
        />

        <FormTargetAndSuggestion
          v-else-if="$route.query?.tab === constants.tabs['target-and-suggestion']"
          ref="formTargetAndSuggestion"
          :draft="draft"
          :is-draft-exist="!!draft"
          @update:validation="onChangeValidation"
        />
      </c-box>
      <c-box
        v-else
        text-align="center"
        margin-block="36px"
      >
        <c-spinner
          thickness="4px"
          speed="0.65s"
          empty-color="green.200"
          color="primary.400"
          size="xl"
        />
        <c-text
          color="primary.400"
          font-weight="500"
        >
          Memuat data Profil Gizi...
        </c-text>
      </c-box>
    </Tabs>

    <BaseModal
      :is-open="!!showModalConfirm"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="image question"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          justify-content="center"
          padding="30px 24px 20px 24px"
          text-align="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
            margin-bottom="4px"
          >
            Sebelum keluar pastikan sudah menyimpan formulir
          </BaseText>

          <BaseText
            size-mobile="14px"
            size-desktop="16px"
            color="#555"
          >
            Data akan tersimpan di draft
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            :is-disabled="isLoadingSave"
            @click="onCloseModalConfirm"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            :is-loading="isLoadingSave"
            @click="onSubmitModalConfirm"
          >
            Keluar
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import _ from 'lodash'
import Tabs from '@/components/meal-plan/_widgets/tabs.vue'
import FormProfile from '@/components/meal-plan/_widgets/form-profile.vue'
import FormBodyConditions from '@/components/meal-plan/_widgets/form-body-conditions.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import FormIntake from '@/components/meal-plan/_widgets/form-nutritional-intake.vue'
import { reqNutritionist_mealPlans_preview } from '@/requests/dietela-api/nutritionist/other'
import {
  tabs,
} from '@/constants/meal-plans'
import { mapGetters, mapMutations } from 'vuex'
import FormTargetAndSuggestion
  from '@/components/meal-plan/_widgets/form-target-and-suggestion.vue'
// import { isAllFormValid } from '@/components/meal-plan/_utils'
import mixinsTabsMealPlan from '@/mixins/mixins-tabs-meal-plan'

export default {
  components: {
    FormTargetAndSuggestion,
    FormIntake,
    Tabs,
    FormProfile,
    FormBodyConditions,
    BaseModal,
    BaseText,
    BaseButton,
  },
  mixins: [mixinsTabsMealPlan],
  beforeRouteLeave(to, form, next) {
    if (this.to || to.params?.bypass || !this.isAuthenticated) {
      next()
    } else {
      this.to = to
      this.showModalConfirm = true
    }
  },
  props: {
    isEdited: {
      type: Boolean,
      default: true,
    },
    isProgramConsultationBasic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      to: null,
      showModalConfirm: false,
      draft: null,
      isLoadingDraft: true,
      isLoadingSave: false,
      dataPreview: {
        optConditions: {},
      },
      validation: {
        'nutritional-condition': 1,
        'nutritional-intake': 1,
        'body-condition': 1,
        'target-and-suggestion': 1,
      },
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
    tab() {
      return this.$route.query.tab ?? this.constants.tabs['nutritional-condition']
    },
    constants() {
      return {
        tabs,
      }
    },
    _tabsRealTime() {
      return [
        { 
          ...this._tabs[0],
          validation: Object.values(this.validation).every((val) => val === 1) ? 1 : -1,
        },
        { 
          ...this._tabs[1],
        },
        {
          ...this._tabs[2],
        },
      ]
    },
  },
  watch: {
    $route() {
      this.init()
    },
    isAuthenticated: {
      handler(val) {
        if (!val) {
          window.removeEventListener('beforeunload', this.onPreventNav)
          this.$router.replace({
            name: 'auth',
            params: {
              bypass: 1,
            },
            query: {
              redirect: this.$route.path,
            },
          })
        }
      },
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.onPreventNav)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onPreventNav)
  },
  created() {
    this.verifyCurrentTab()
    this.init()
    this.getMenuRecommendationWeekDays()
  },
  methods: {
    ...mapMutations({
      setNutritionalConditions: 'mealPlan/setNutritionalConditions',
      setMealPlanPreview: 'mealPlan/setMealPlanPreview',
      setMenuRecommendationWeeksDays: 'mealPlan/setMenuRecommendationWeeksDays',
    }),
    verifyCurrentTab() {
      if (!(Object.values(this.constants.tabs)).includes(this.$route.query?.tab)) {
        this.$router.replace({
          name: 'nutri.meal-plan.profile.edit',
          params: this.$route.params,
          query: {
            tab: this.constants.tabs['nutritional-condition'],
          },
        })
      }
    },
    async init() {
      try {
        this.isLoadingDraft = true
        const res = await reqNutriMealPlan.getMealPlanData(this.$store.getters.axios, {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params?.month,
        })
        this.setNutritionalConditions(res.data?.data?.nutritionalConditions)
  
        const resPreviewOptConditions = await reqNutritionist_mealPlans_preview(this.$store.getters.axios,
          {
            clientId: this.$route.params.clientId,
            programId: this.$route.params.programId,
            month: this.$route.params?.month,
          })
        this.dataPreview.optConditions = resPreviewOptConditions?.data?.data || {}
  
        if (res.data?.data && !_.isEmpty(res.data?.data)) {
          this.draft = res.data.data
          this.setMealPlanPreview(res?.data?.data)
        }
        this.onUpdateValidationWithDraft()
      } catch (err) {
        //
      } finally {
        this.isLoadingDraft = false
      }
    },
    onCloseModalConfirm() {
      if (!this.isLoadingSave) {
        this.showModalConfirm = false
        this.to = null
      }
    },
    async onSubmitModalConfirm() {
      this.isLoadingSave = true
      try {
        switch (this.tab) {
          case this.constants.tabs['nutritional-condition']:
            await this.$refs.formProfile.onSaveDraft()
            break
          case this.constants.tabs['nutritional-intake']:
            await this.$refs.formIntake.onSaveDraft()
            break
          case this.constants.tabs['body-condition']:
            await this.$refs.formConditions.onSaveDraft()
            break
          case this.constants.tabs['target-and-suggestion']:
            await this.$refs.formTargetAndSuggestion.onSaveDraft()
            break
        }
      } finally {
        this.isLoadingSave = false
        this.showModalConfirm = false
        this.$router.push(this.to)
      }
    },
    onPreventNav(event) {
      event.preventDefault()
      event.returnValue = ''
    },
    async getMenuRecommendationWeekDays() {
      const res = await reqNutriMealPlan.getMenuRecommendationWeekDays(this.$store.getters.axios,
        {
          clientId: this.$route.params?.clientId,
          programId: this.$route.params?.programId,
          month: this.$route.params.month,
        },
      )
      this.setMenuRecommendationWeeksDays(res?.data?.data)
    },
    onUpdateValidationWithDraft() {
      this.validation['nutritional-condition'] = this.draft?.nutritionalConditions?.validation || 0
      this.validation['nutritional-intake'] = this.draft?.nutritionalIntake?.validation || 0
      this.validation['body-condition'] = this.draft?.bodyConditions?.validation || 0
      this.validation['target-and-suggestion'] = this.draft?.targetAndSuggestions?.validation || 0
    },
    onChangeValidation(value) {
      if (this.validation[value?.form]) {
        this.validation[value?.form] = value?.validation
      }
    },
  },
}
</script>
