import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      _mealPlanPreview: 'mealPlan/mealPlanPreview',
      _menuRecommendationWeeksDays: 'mealPlan/menuRecommendationWeeksDays',
    }),
    isNutritionalConditionsValid() {
      return this._mealPlanPreview?.nutritionalConditions?.validation || 0
    },
    isNutritionalIntakeValid() {
      return this._mealPlanPreview?.nutritionalIntake?.validation || 0
    },
    isBodyConditionsValid() {
      return this._mealPlanPreview?.bodyConditions?.validation || 0
    },
    isTargetAndSuggestionsValid() {
      return this._mealPlanPreview?.targetAndSuggestions?.validation || 0
    },
    isNutritionalProfileValid() {
      const nutritionalConditions = this._mealPlanPreview?.nutritionalConditions && this._mealPlanPreview?.nutritionalConditions?.validation === 1
      const nutritionalIntake = this._mealPlanPreview?.nutritionalIntake && this._mealPlanPreview?.nutritionalIntake?.validation === 1
      const bodyConditions = this._mealPlanPreview?.bodyConditions && this._mealPlanPreview?.bodyConditions?.validation === 1
      const targetAndSuggestions = this._mealPlanPreview?.targetAndSuggestions && this._mealPlanPreview?.targetAndSuggestions?.validation === 1

      return nutritionalConditions && nutritionalIntake && bodyConditions && targetAndSuggestions ? 1 : -1
    },
    isMealPlanSchedulesValid() {
      const isMealPlanSchedulesExist = this._mealPlanPreview?.mealPlanSchedules && this._mealPlanPreview?.mealPlanSchedules?.length > 0
      
      if (!isMealPlanSchedulesExist) {
        return 0
      }

      const isAllMealPlanSchedulesValid = this._mealPlanPreview?.mealPlanSchedules?.every((it) => it?.validation === 1)

      if (isAllMealPlanSchedulesValid) {
        return 1
      }
      return -1
    },
    isMealRecommendationsValid() {
      return this._mealPlanPreview?.mealRecommendations?.validation || 0
    },
    isMenuRecommendationsValid() {
      const isMenuRecommendationsExist = this._menuRecommendationWeeksDays && this._menuRecommendationWeeksDays?.weeks?.length > 0

      if (!isMenuRecommendationsExist) {
        return 0
      }

      const mappingAllValidation = this._menuRecommendationWeeksDays?.weeks?.map(it => ({
        week: it?.week,
        allValidated: it?.days?.every(day => day?.validation === 1),
      }))

      if (mappingAllValidation.every((it) => it?.allValidated === true)) {
        return 1
      }
      return -1
    },
    _tabs() {
      return [
        { 
          label: 'Profil Gizi', 
          value: 'profile', 
          isDisabled: false, 
          validation: this.isNutritionalProfileValid === 1 ? 1 : -1,
        },
        { 
          label: 'Rencana Makan', 
          value: 'meal-plan', 
          isDisabled: false, 
          validation: this.isMealPlanSchedulesValid === 1 && this.isMealRecommendationsValid === 1 ? 1 : -1,
        },
        { 
          label: 'Rekomendasi Menu', 
          value: 'recommendation', 
          isDisabled: false, 
          validation: this.isMenuRecommendationsValid === 1 ? 1 : -1,
        },
      ]
    },
    _subTabs() {
      return [
        { 
          label: 'Rencana Makan', 
          value: 'meal-plan', 
          validation: this.isMealPlanSchedulesValid,
        },
        { 
          label: 'Anjuran Makan', 
          value: 'meal-recommendation',
          validation: this.isMealRecommendationsValid,
        },
      ]
    },
  },
}