<template>
  <c-grid
    w="100%"
    :template-columns="['repeat(4, 1fr)']"
    :gap="['16px']"
    margin-bottom="16px"
  >
    <c-flex
      v-for="(value, key) in steps"
      :key="key"
      flex-direction="column"
      justify-content="center"
      align-items="center"
      gap="8px"
    >
      <c-text
        font-size="14px"
        font-weight="500"
        :color="getColor(key, 'text')"
      >
        {{ value }}
      </c-text>
      <c-box
        width="100%"
        height="8px"
        border-radius="8px"
        :background-color="getColor(key, 'background')"
      />
    </c-flex>
  </c-grid>
</template>

<script>
export default {
  props: {
    draft: {
      type: Object,
      default: () => {},
    },
    nutritionalConditions: {
      type: [Number, null],
      default: null, // 1: valid, 0: blank, -1: invalid, null: using data response `validation` from draft
    },
    nutritionalIntake: {
      type: [Number, null],
      default: null, // 1: valid, 0: blank, -1: invalid, null: using data response `validation` from draft
    },
    bodyConditions: {
      type: [Number, null],
      default: null, // 1: valid, 0: blank, -1: invalid, null: using data response `validation` from draft
    },
    targetAndSuggestions: {
      type: [Number, null],
      default: null, // 1: valid, 0: blank, -1: invalid, null: using data response `validation` from draft
    },
  },
  computed: {
    steps() {
      return {
        nutritionalConditions: 'Kondisi Giziku',
        nutritionalIntake: 'Asupan Gizi',
        bodyConditions: 'Kondisi Tubuh',
        targetAndSuggestions: 'Target & Saran',
      }
    },
  },
  methods: {
    getColor(key, type) {
      if (this.$props[key] !== null) {
        if (this.$props[key] === 1) {
          return 'primary.400'
        }
        return 'danger.400'
      }
      if (this.draft == null || !this.draft[key]) {
        if (type === 'text') {
          return 'neutral.lightGray'
        }
        return 'neutral.superLightGray'
      }
      if (key === 'nutritionalConditions') {
        if (this.draft[key]?.validation === 1) {
          return 'primary.400'
        }
        return 'danger.400'
      }

      if (key === 'nutritionalIntake') {
        if (this.draft[key]?.validation === 1) {
          return 'primary.400'
        }
        return 'danger.400'
      }

      if (key === 'bodyConditions') {
        if (this.draft[key]?.validation === 1) {
          return 'primary.400'
        }
        return 'danger.400'
      }

      if (key === 'targetAndSuggestions') {
        if (this.draft[key].validation === 1) {
          return 'primary.400'
        }
        return 'danger.400'
      }

      return 'neutral.black' // delete this if no longer used
    },
  },
}
</script>
