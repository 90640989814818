<template>
  <c-box>
    <FormSelect
      v-model="form.indicator"
      :label="`Indikator ${index + 1}`"
      :placeholder="`Pilih indikator ${index + 1}`"
      :options="indicatorOptions"
      is-required
      is-helper
      :is-invalid="isInvalidField($v.form?.indicator)"
      :invalid-text="parseErrors(`Indikator ${index + 1}`, $v.form?.indicator, customMessageErrors)"
      @blur="$v.form.indicator.$touch"
    />
    <FormInput
      v-if="form.indicator === 'Lainnya'"
      v-model="form.indicatorOther"
      type="text"
      :label="`Indicator Lainnya ${index + 1}`"
      placeholder="Masukkan indikator lainnya"
      is-required
      is-helper
      helper-text-color="#888"
      helper-text-type="info"
      helper-text="Maksimal 20 karakter"
      :is-invalid="isInvalidField($v.form?.indicatorOther)"
      :invalid-text="parseErrors(`Indikator Lainnya`, $v.form?.indicatorOther, customMessageErrors)"
      is-without-label
      @blur="$v.form.indicatorOther.$touch"
    />
    <c-grid
      w="100%"
      :template-columns="['repeat(2, 1fr)']"
      :gap="['16px']"
    >
      <c-box>
        <c-text
          :font-size="['14px', '16px']"
          font-weight="400"
          font-family="Roboto"
          padding="0px 12px 4px 0px"
          :color="(isInvalidField($v.form?.currentCondition) || isInvalidField($v.form?.currentConditionUnit) ? '#D32737' : (form.currentCondition && form.currentConditionUnit ? '#008C81' : '#555'))"
        >
          Kondisi Saat ini {{ index + 1 }} *
        </c-text>
        <c-flex
          :template-columns="['repeat(2, 1fr)']"
        >
          <FormInput
            v-model="form.currentCondition"
            v-chakra="{
              'input': {
                borderTopRightRadius: '0px !important',
                borderBottomRightRadius: '0px !important',
              }
            }"
            type="text"
            :label="`Kondisi Saat ini ${index + 1}`"
            placeholder="Masukkan kondisi saat ini"
            is-required
            :is-invalid="isInvalidField($v.form?.currentCondition)"
            :invalid-text="parseErrors(`Kondisi Saat ini ${index + 1}`, $v.form?.currentCondition, customMessageErrors)"
            is-without-label
            flex-grow="1"
            @blur="$v.form.currentCondition.$touch"
          />
          <c-box
            flex-basis="0"
            flex-shrink="0"
            :min-width="form.currentConditionUnit ? '220px' : '170px'"
            transition-duration="300ms"
          >
            <FormSelect
              v-model="form.currentConditionUnit"
              v-chakra="{
                'select': {
                  borderTopLeftRadius: '0px !important',
                  borderBottomLeftRadius: '0px !important',
                  backgroundColor: '#F2F2F2',
                }
              }"
              label=""
              :placeholder="`Pilih satuan`"
              :options="currentConditionOptions"
              is-required
              :is-invalid="isInvalidField($v.form?.currentConditionUnit)"
              :invalid-text="parseErrors('Satuan', $v.form?.currentConditionUnit, customMessageErrors)"
              is-without-label
              @blur="$v.form.currentConditionUnit.$touch"
            />
            <FormInput
              v-if="form.currentConditionUnit === 'Lainnya'"
              v-model="form.currentConditionUnitOther"
              type="text"
              :label="`Kondisi Saat ini ${index + 1}`"
              placeholder="Masukkan lainnya"
              is-required
              is-helper
              helper-text-color="#888"
              helper-text-type="info"
              helper-text="Maksimal 20 karakter"
              :is-invalid="isInvalidField($v.form?.currentConditionUnitOther)"
              :invalid-text="parseErrors('Lainnya', $v.form?.currentConditionUnitOther, customMessageErrors)"
              is-without-label
              @blur="$v.form.currentConditionUnitOther.$touch"
            />
          </c-box>
        </c-flex>
      </c-box>

      <c-box>
        <c-text
          :font-size="['14px', '16px']"
          font-weight="400"
          font-family="Roboto"
          padding="0px 12px 4px 0px"
          :color="(isInvalidField($v.form?.standardValue) || isInvalidField($v.form?.standardValueUnit) ? '#D32737' : (form.standardValue && form.standardValueUnit ? '#008C81' : '#555'))"
        >
          Nilai Standard {{ index + 1 }} *
        </c-text>
        <c-flex
          :template-columns="['repeat(2, 1fr)']"
        >
          <FormInput
            v-model="form.standardValue"
            v-chakra="{
              'input': {
                borderTopRightRadius: '0px !important',
                borderBottomRightRadius: '0px !important',
              }
            }"
            type="text"
            :label="`Nilai Standard ${index + 1}`"
            placeholder="Masukkan nilai standard"
            is-required
            :is-invalid="isInvalidField($v.form?.standardValue)"
            :invalid-text="parseErrors(`Nilai Standard ${index + 1}`, $v.form?.standardValue, customMessageErrors)"
            is-without-label
            flex-grow="1"
            @blur="$v.form.standardValue.$touch"
          />
          <c-box
            flex-basis="0"
            flex-shrink="0"
            :min-width="form.standardValueUnit ? '220px' : '170px'"
            transition-duration="300ms"
          >
            <FormSelect
              v-model="form.standardValueUnit"
              v-chakra="{
                'select': {
                  borderTopLeftRadius: '0px !important',
                  borderBottomLeftRadius: '0px !important',
                  backgroundColor: '#F2F2F2',
                }
              }"
              label=""
              :placeholder="`Pilih satuan`"
              :options="standardValueOptions"
              is-required
              :is-invalid="isInvalidField($v.form?.standardValueUnit)"
              :invalid-text="parseErrors('Satuan', $v.form?.standardValueUnit, customMessageErrors)"
              is-without-label
              @blur="$v.form.standardValueUnit.$touch"
            />
            <FormInput
              v-if="form.standardValueUnit === 'Lainnya'"
              v-model="form.standardValueUnitOther"
              type="text"
              :label="`Nilai Standard ini ${index + 1}`"
              placeholder="Masukkan lainnya"
              is-required
              is-helper
              helper-text-color="#888"
              helper-text-type="info"
              helper-text="Maksimal 20 karakter"
              :is-invalid="isInvalidField($v.form?.standardValueUnitOther)"
              :invalid-text="parseErrors(`Lainnya`, $v.form?.standardValueUnitOther, customMessageErrors)"
              is-without-label
              @blur="$v.form.standardValueUnitOther.$touch"
            />
          </c-box>
        </c-flex>
      </c-box>
    </c-grid>

    <FormSelect
      v-model="form.conclusion"
      :label="`Kesimpulan ${index + 1}`"
      :placeholder="`Pilih kesimpulan ${index + 1}`"
      :options="conclusionOptions"
      is-required
      :is-invalid="isInvalidField($v.form?.conclusion)"
      :invalid-text="parseErrors(`Kesimpulan ${index + 1}`, $v.form?.conclusion, customMessageErrors)"
      @blur="$v.form.conclusion.$touch"
    />
    <FormInput
      v-if="form.conclusion === 'Lainnya'"
      v-model="form.conclusionOther"
      type="text"
      :label="`Kesimpulan Lainnya ${index + 1}`"
      placeholder="Masukkan kesimpulan lainnya"
      is-required
      is-helper
      helper-text-color="#888"
      helper-text-type="info"
      helper-text="Maksimal 20 karakter"
      :is-invalid="isInvalidField($v.form?.conclusionOther)"
      :invalid-text="parseErrors(`Kesimpulan Lainnya`, $v.form?.conclusionOther, customMessageErrors)"
      is-without-label
      @blur="$v.form.conclusionOther.$touch"
    />
  </c-box>
</template>

<script>
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import FormSelect from '@/views/client/kuisioner/forms/form-select-2'
import FormInput from '@/views/client/kuisioner/forms/form-input-2'

export default {
  components: {
    FormSelect,
    FormInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          indicator: null,
          indicatorOther: null,
          currentCondition: null,
          currentConditionUnit: null,
          currentConditionUnitOther: null,
          standardValue: null,
          standardValueUnit: null,
          standardValueUnitOther: null,
          conclusion: null,
          conclusionOther: null,
        }
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    currentConditionOptions: {
      type: Array,
      default: () => [],
    },
    standardValueOptions: {
      type: Array,
      default: () => [],
    },
    conclusionOptions: {
      type: Array,
      default: () => [],
    },
    indicatorOptions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input'],
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    customMessageErrors() {
      return {
        required: '{{fieldName}} wajib diisi',
        maxLength: '{{fieldName}} melebihi {{maxLength.max}} karakter',
      }
    },
  },
  watch: {},
  validations: {
    form: {
      indicator: {
        required,
      },
      indicatorOther: {
        required: requiredIf((model) => model.indicator === 'Lainnya'),
        maxLength: maxLength(20),
      },
      currentCondition: {
        required,
      },
      currentConditionUnit: {
        required,
      },
      currentConditionUnitOther: {
        required: requiredIf((model) => model.currentConditionUnit === 'Lainnya'),
        maxLength: maxLength(20),
      },
      standardValue: {
        required,
      },
      standardValueUnit: {
        required,
      },
      standardValueUnitOther: {
        required: requiredIf((model) => model.standardValueUnit === 'Lainnya'),
        maxLength: maxLength(20),
      },
      conclusion: {
        required,
      },
      conclusionOther: {
        required: requiredIf((model) => model.conclusion === 'Lainnya'),
        maxLength: maxLength(20),
      },
    },
  },
  methods: {
    isInvalidField,
    parseErrors,
    onFormTouch() {
      this.$v.form.indicator.$touch()
      this.$v.form.currentCondition.$touch()
      this.$v.form.currentConditionUnit.$touch()
      this.$v.form.standardValue.$touch()
      this.$v.form.standardValueUnit.$touch()
      this.$v.form.currentCondition.$touch()
      this.$v.form.conclusion.$touch()

      if (this.form.indicator === 'Lainnya') {
        this.$v.form.indicatorOther.$touch()
      }

      if (this.form.currentConditionUnit === 'Lainnya') {
        this.$v.form.currentConditionUnitOther.$touch()
      }

      if (this.form.standardValueUnit === 'Lainnya') {
        this.$v.form.standardValueUnitOther.$touch()
      }

      if (this.form.conclusion === 'Lainnya') {
        this.$v.form.conclusionOther.$touch()
      }
    },
  },
}
</script>

<style>

</style>