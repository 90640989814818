import { render, staticRenderFns } from "./form-select-2.vue?vue&type=template&id=2b6fe486&scoped=true&"
import script from "./form-select-2.vue?vue&type=script&lang=js&"
export * from "./form-select-2.vue?vue&type=script&lang=js&"
import style0 from "./form-select-2.vue?vue&type=style&index=0&id=2b6fe486&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b6fe486",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFormLabel: require('@chakra-ui/vue').CFormLabel, CSelect: require('@chakra-ui/vue').CSelect, CInput: require('@chakra-ui/vue').CInput, CFormControl: require('@chakra-ui/vue').CFormControl, CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CFormHelperText: require('@chakra-ui/vue').CFormHelperText})
