<template>
  <!-- MODAL SUCCESS EDIT/ADD DATA -->
  <BaseModal
    :is-open="isOpen"
    :close-on-overlay-click="false"
    :with-button-close="false"
  >
    <template #header>
      <c-box
        display="flex"
        justify-content="center"
        margin="24px 0 0 0"
      >
        <CImage
          width="150px"
          object-fit="cover"
          :src="require('@/assets/images/success-image.svg')"
          alt="Image Confirmation"
        />
      </c-box>
    </template>
    <template #body>
      <c-box
        display="flex"
        flex-direction="column"
        align-items="center"
        px="24px"
        py="16px"
        text-align="center"
        max-width="500px"
        mx="auto"
      >
        <BaseText
          size-mobile="16px"
          size-desktop="20px"
          color="primary.400"
        >
          Data Profil Gizi berhasil disimpan
        </BaseText>
      </c-box>
    </template>
    <template #footer>
      <c-box
        width="100%"
        padding-left="24px"
        padding-right="24px"
        margin="24px 0"
      >
        <BaseButton
          :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
          right-svg-icon-color="white"
          color="primary"
          rounded="1000px"
          width="100%"
          @click="close"
        >
          Oke
        </BaseButton>
      </c-box>
    </template>
  </BaseModal>
</template>

<script>
import { CBox, CImage } from '@chakra-ui/vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseButton from '@/components/elements/base-button.vue'

export default {
  name: 'ModalSuccessSaveData',
  components: { BaseButton, CImage, BaseModal, BaseText, CBox },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  methods: {
    close() {
      this.$emit('close')
      this.$router.push({ 
        name: 'nutri.meal-plan.profile.detail',
        params: {
          ...this.$route.params,
          bypass: 1,
        },
      })
    },
  },
}
</script>
