var render = function () {
  var _vm$$v$form, _vm$$v$form2, _vm$$v$form3, _vm$$v$form4, _vm$$v$form5, _vm$$v$form6, _vm$$v$form7, _vm$$v$form8, _vm$$v$form9, _vm$$v$form10, _vm$$v$form11, _vm$$v$form12, _vm$$v$form13, _vm$$v$form14, _vm$$v$form15, _vm$$v$form16, _vm$$v$form17, _vm$$v$form18, _vm$$v$form19, _vm$$v$form20, _vm$$v$form21, _vm$$v$form22, _vm$$v$form23, _vm$$v$form24;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('FormSelect', {
    attrs: {
      "label": "Indikator ".concat(_vm.index + 1),
      "placeholder": "Pilih indikator ".concat(_vm.index + 1),
      "options": _vm.indicatorOptions,
      "is-required": "",
      "is-helper": "",
      "is-invalid": _vm.isInvalidField((_vm$$v$form = _vm.$v.form) === null || _vm$$v$form === void 0 ? void 0 : _vm$$v$form.indicator),
      "invalid-text": _vm.parseErrors("Indikator ".concat(_vm.index + 1), (_vm$$v$form2 = _vm.$v.form) === null || _vm$$v$form2 === void 0 ? void 0 : _vm$$v$form2.indicator, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.indicator.$touch
    },
    model: {
      value: _vm.form.indicator,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "indicator", $$v);
      },
      expression: "form.indicator"
    }
  }), _vm.form.indicator === 'Lainnya' ? _c('FormInput', {
    attrs: {
      "type": "text",
      "label": "Indicator Lainnya ".concat(_vm.index + 1),
      "placeholder": "Masukkan indikator lainnya",
      "is-required": "",
      "is-helper": "",
      "helper-text-color": "#888",
      "helper-text-type": "info",
      "helper-text": "Maksimal 20 karakter",
      "is-invalid": _vm.isInvalidField((_vm$$v$form3 = _vm.$v.form) === null || _vm$$v$form3 === void 0 ? void 0 : _vm$$v$form3.indicatorOther),
      "invalid-text": _vm.parseErrors("Indikator Lainnya", (_vm$$v$form4 = _vm.$v.form) === null || _vm$$v$form4 === void 0 ? void 0 : _vm$$v$form4.indicatorOther, _vm.customMessageErrors),
      "is-without-label": ""
    },
    on: {
      "blur": _vm.$v.form.indicatorOther.$touch
    },
    model: {
      value: _vm.form.indicatorOther,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "indicatorOther", $$v);
      },
      expression: "form.indicatorOther"
    }
  }) : _vm._e(), _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(2, 1fr)'],
      "gap": ['16px']
    }
  }, [_c('c-box', [_c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "font-family": "Roboto",
      "padding": "0px 12px 4px 0px",
      "color": _vm.isInvalidField((_vm$$v$form5 = _vm.$v.form) === null || _vm$$v$form5 === void 0 ? void 0 : _vm$$v$form5.currentCondition) || _vm.isInvalidField((_vm$$v$form6 = _vm.$v.form) === null || _vm$$v$form6 === void 0 ? void 0 : _vm$$v$form6.currentConditionUnit) ? '#D32737' : _vm.form.currentCondition && _vm.form.currentConditionUnit ? '#008C81' : '#555'
    }
  }, [_vm._v(" Kondisi Saat ini " + _vm._s(_vm.index + 1) + " * ")]), _c('c-flex', {
    attrs: {
      "template-columns": ['repeat(2, 1fr)']
    }
  }, [_c('FormInput', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'input': {
          borderTopRightRadius: '0px !important',
          borderBottomRightRadius: '0px !important'
        }
      },
      expression: "{\n            'input': {\n              borderTopRightRadius: '0px !important',\n              borderBottomRightRadius: '0px !important',\n            }\n          }"
    }],
    attrs: {
      "type": "text",
      "label": "Kondisi Saat ini ".concat(_vm.index + 1),
      "placeholder": "Masukkan kondisi saat ini",
      "is-required": "",
      "is-invalid": _vm.isInvalidField((_vm$$v$form7 = _vm.$v.form) === null || _vm$$v$form7 === void 0 ? void 0 : _vm$$v$form7.currentCondition),
      "invalid-text": _vm.parseErrors("Kondisi Saat ini ".concat(_vm.index + 1), (_vm$$v$form8 = _vm.$v.form) === null || _vm$$v$form8 === void 0 ? void 0 : _vm$$v$form8.currentCondition, _vm.customMessageErrors),
      "is-without-label": "",
      "flex-grow": "1"
    },
    on: {
      "blur": _vm.$v.form.currentCondition.$touch
    },
    model: {
      value: _vm.form.currentCondition,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currentCondition", $$v);
      },
      expression: "form.currentCondition"
    }
  }), _c('c-box', {
    attrs: {
      "flex-basis": "0",
      "flex-shrink": "0",
      "min-width": _vm.form.currentConditionUnit ? '220px' : '170px',
      "transition-duration": "300ms"
    }
  }, [_c('FormSelect', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'select': {
          borderTopLeftRadius: '0px !important',
          borderBottomLeftRadius: '0px !important',
          backgroundColor: '#F2F2F2'
        }
      },
      expression: "{\n              'select': {\n                borderTopLeftRadius: '0px !important',\n                borderBottomLeftRadius: '0px !important',\n                backgroundColor: '#F2F2F2',\n              }\n            }"
    }],
    attrs: {
      "label": "",
      "placeholder": "Pilih satuan",
      "options": _vm.currentConditionOptions,
      "is-required": "",
      "is-invalid": _vm.isInvalidField((_vm$$v$form9 = _vm.$v.form) === null || _vm$$v$form9 === void 0 ? void 0 : _vm$$v$form9.currentConditionUnit),
      "invalid-text": _vm.parseErrors('Satuan', (_vm$$v$form10 = _vm.$v.form) === null || _vm$$v$form10 === void 0 ? void 0 : _vm$$v$form10.currentConditionUnit, _vm.customMessageErrors),
      "is-without-label": ""
    },
    on: {
      "blur": _vm.$v.form.currentConditionUnit.$touch
    },
    model: {
      value: _vm.form.currentConditionUnit,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currentConditionUnit", $$v);
      },
      expression: "form.currentConditionUnit"
    }
  }), _vm.form.currentConditionUnit === 'Lainnya' ? _c('FormInput', {
    attrs: {
      "type": "text",
      "label": "Kondisi Saat ini ".concat(_vm.index + 1),
      "placeholder": "Masukkan lainnya",
      "is-required": "",
      "is-helper": "",
      "helper-text-color": "#888",
      "helper-text-type": "info",
      "helper-text": "Maksimal 20 karakter",
      "is-invalid": _vm.isInvalidField((_vm$$v$form11 = _vm.$v.form) === null || _vm$$v$form11 === void 0 ? void 0 : _vm$$v$form11.currentConditionUnitOther),
      "invalid-text": _vm.parseErrors('Lainnya', (_vm$$v$form12 = _vm.$v.form) === null || _vm$$v$form12 === void 0 ? void 0 : _vm$$v$form12.currentConditionUnitOther, _vm.customMessageErrors),
      "is-without-label": ""
    },
    on: {
      "blur": _vm.$v.form.currentConditionUnitOther.$touch
    },
    model: {
      value: _vm.form.currentConditionUnitOther,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currentConditionUnitOther", $$v);
      },
      expression: "form.currentConditionUnitOther"
    }
  }) : _vm._e()], 1)], 1)], 1), _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "font-family": "Roboto",
      "padding": "0px 12px 4px 0px",
      "color": _vm.isInvalidField((_vm$$v$form13 = _vm.$v.form) === null || _vm$$v$form13 === void 0 ? void 0 : _vm$$v$form13.standardValue) || _vm.isInvalidField((_vm$$v$form14 = _vm.$v.form) === null || _vm$$v$form14 === void 0 ? void 0 : _vm$$v$form14.standardValueUnit) ? '#D32737' : _vm.form.standardValue && _vm.form.standardValueUnit ? '#008C81' : '#555'
    }
  }, [_vm._v(" Nilai Standard " + _vm._s(_vm.index + 1) + " * ")]), _c('c-flex', {
    attrs: {
      "template-columns": ['repeat(2, 1fr)']
    }
  }, [_c('FormInput', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'input': {
          borderTopRightRadius: '0px !important',
          borderBottomRightRadius: '0px !important'
        }
      },
      expression: "{\n            'input': {\n              borderTopRightRadius: '0px !important',\n              borderBottomRightRadius: '0px !important',\n            }\n          }"
    }],
    attrs: {
      "type": "text",
      "label": "Nilai Standard ".concat(_vm.index + 1),
      "placeholder": "Masukkan nilai standard",
      "is-required": "",
      "is-invalid": _vm.isInvalidField((_vm$$v$form15 = _vm.$v.form) === null || _vm$$v$form15 === void 0 ? void 0 : _vm$$v$form15.standardValue),
      "invalid-text": _vm.parseErrors("Nilai Standard ".concat(_vm.index + 1), (_vm$$v$form16 = _vm.$v.form) === null || _vm$$v$form16 === void 0 ? void 0 : _vm$$v$form16.standardValue, _vm.customMessageErrors),
      "is-without-label": "",
      "flex-grow": "1"
    },
    on: {
      "blur": _vm.$v.form.standardValue.$touch
    },
    model: {
      value: _vm.form.standardValue,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "standardValue", $$v);
      },
      expression: "form.standardValue"
    }
  }), _c('c-box', {
    attrs: {
      "flex-basis": "0",
      "flex-shrink": "0",
      "min-width": _vm.form.standardValueUnit ? '220px' : '170px',
      "transition-duration": "300ms"
    }
  }, [_c('FormSelect', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'select': {
          borderTopLeftRadius: '0px !important',
          borderBottomLeftRadius: '0px !important',
          backgroundColor: '#F2F2F2'
        }
      },
      expression: "{\n              'select': {\n                borderTopLeftRadius: '0px !important',\n                borderBottomLeftRadius: '0px !important',\n                backgroundColor: '#F2F2F2',\n              }\n            }"
    }],
    attrs: {
      "label": "",
      "placeholder": "Pilih satuan",
      "options": _vm.standardValueOptions,
      "is-required": "",
      "is-invalid": _vm.isInvalidField((_vm$$v$form17 = _vm.$v.form) === null || _vm$$v$form17 === void 0 ? void 0 : _vm$$v$form17.standardValueUnit),
      "invalid-text": _vm.parseErrors('Satuan', (_vm$$v$form18 = _vm.$v.form) === null || _vm$$v$form18 === void 0 ? void 0 : _vm$$v$form18.standardValueUnit, _vm.customMessageErrors),
      "is-without-label": ""
    },
    on: {
      "blur": _vm.$v.form.standardValueUnit.$touch
    },
    model: {
      value: _vm.form.standardValueUnit,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "standardValueUnit", $$v);
      },
      expression: "form.standardValueUnit"
    }
  }), _vm.form.standardValueUnit === 'Lainnya' ? _c('FormInput', {
    attrs: {
      "type": "text",
      "label": "Nilai Standard ini ".concat(_vm.index + 1),
      "placeholder": "Masukkan lainnya",
      "is-required": "",
      "is-helper": "",
      "helper-text-color": "#888",
      "helper-text-type": "info",
      "helper-text": "Maksimal 20 karakter",
      "is-invalid": _vm.isInvalidField((_vm$$v$form19 = _vm.$v.form) === null || _vm$$v$form19 === void 0 ? void 0 : _vm$$v$form19.standardValueUnitOther),
      "invalid-text": _vm.parseErrors("Lainnya", (_vm$$v$form20 = _vm.$v.form) === null || _vm$$v$form20 === void 0 ? void 0 : _vm$$v$form20.standardValueUnitOther, _vm.customMessageErrors),
      "is-without-label": ""
    },
    on: {
      "blur": _vm.$v.form.standardValueUnitOther.$touch
    },
    model: {
      value: _vm.form.standardValueUnitOther,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "standardValueUnitOther", $$v);
      },
      expression: "form.standardValueUnitOther"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('FormSelect', {
    attrs: {
      "label": "Kesimpulan ".concat(_vm.index + 1),
      "placeholder": "Pilih kesimpulan ".concat(_vm.index + 1),
      "options": _vm.conclusionOptions,
      "is-required": "",
      "is-invalid": _vm.isInvalidField((_vm$$v$form21 = _vm.$v.form) === null || _vm$$v$form21 === void 0 ? void 0 : _vm$$v$form21.conclusion),
      "invalid-text": _vm.parseErrors("Kesimpulan ".concat(_vm.index + 1), (_vm$$v$form22 = _vm.$v.form) === null || _vm$$v$form22 === void 0 ? void 0 : _vm$$v$form22.conclusion, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.conclusion.$touch
    },
    model: {
      value: _vm.form.conclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "conclusion", $$v);
      },
      expression: "form.conclusion"
    }
  }), _vm.form.conclusion === 'Lainnya' ? _c('FormInput', {
    attrs: {
      "type": "text",
      "label": "Kesimpulan Lainnya ".concat(_vm.index + 1),
      "placeholder": "Masukkan kesimpulan lainnya",
      "is-required": "",
      "is-helper": "",
      "helper-text-color": "#888",
      "helper-text-type": "info",
      "helper-text": "Maksimal 20 karakter",
      "is-invalid": _vm.isInvalidField((_vm$$v$form23 = _vm.$v.form) === null || _vm$$v$form23 === void 0 ? void 0 : _vm$$v$form23.conclusionOther),
      "invalid-text": _vm.parseErrors("Kesimpulan Lainnya", (_vm$$v$form24 = _vm.$v.form) === null || _vm$$v$form24 === void 0 ? void 0 : _vm$$v$form24.conclusionOther, _vm.customMessageErrors),
      "is-without-label": ""
    },
    on: {
      "blur": _vm.$v.form.conclusionOther.$touch
    },
    model: {
      value: _vm.form.conclusionOther,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "conclusionOther", $$v);
      },
      expression: "form.conclusionOther"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }