var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "width": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/success-image.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Data Profil Gizi berhasil disimpan ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.close
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }