import { render, staticRenderFns } from "./form-target-and-suggestion.vue?vue&type=template&id=6c800b10&"
import script from "./form-target-and-suggestion.vue?vue&type=script&lang=js&"
export * from "./form-target-and-suggestion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CButton: require('@chakra-ui/vue').CButton, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CGrid: require('@chakra-ui/vue').CGrid, CImage: require('@chakra-ui/vue').CImage})
