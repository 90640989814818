var render = function () {
  var _vm$$route$query, _vm$$route$query2, _vm$$route$query3, _vm$$route$query4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "margin-top": "24px"
    }
  }, [_c('Tabs', {
    attrs: {
      "active-tab": "profile",
      "mode": "edit",
      "is-program-consultation-basic": _vm.isProgramConsultationBasic,
      "tabs": _vm._tabsRealTime
    }
  }, [!_vm.isLoadingDraft ? _c('c-box', [((_vm$$route$query = _vm.$route.query) === null || _vm$$route$query === void 0 ? void 0 : _vm$$route$query.tab) === _vm.constants.tabs['nutritional-condition'] ? _c('FormProfile', {
    ref: "formProfile",
    attrs: {
      "draft": _vm.draft,
      "is-draft-exist": !!_vm.draft
    },
    on: {
      "update:validation": _vm.onChangeValidation
    }
  }) : ((_vm$$route$query2 = _vm.$route.query) === null || _vm$$route$query2 === void 0 ? void 0 : _vm$$route$query2.tab) === _vm.constants.tabs['nutritional-intake'] ? _c('FormIntake', {
    ref: "formIntake",
    attrs: {
      "data-preview": _vm.dataPreview,
      "draft": _vm.draft,
      "is-draft-exist": !!_vm.draft
    },
    on: {
      "update:validation": _vm.onChangeValidation
    }
  }) : ((_vm$$route$query3 = _vm.$route.query) === null || _vm$$route$query3 === void 0 ? void 0 : _vm$$route$query3.tab) === _vm.constants.tabs['body-condition'] ? _c('FormBodyConditions', {
    ref: "formConditions",
    attrs: {
      "draft": _vm.draft,
      "is-draft-exist": !!_vm.draft
    },
    on: {
      "update:validation": _vm.onChangeValidation
    }
  }) : ((_vm$$route$query4 = _vm.$route.query) === null || _vm$$route$query4 === void 0 ? void 0 : _vm$$route$query4.tab) === _vm.constants.tabs['target-and-suggestion'] ? _c('FormTargetAndSuggestion', {
    ref: "formTargetAndSuggestion",
    attrs: {
      "draft": _vm.draft,
      "is-draft-exist": !!_vm.draft
    },
    on: {
      "update:validation": _vm.onChangeValidation
    }
  }) : _vm._e()], 1) : _c('c-box', {
    attrs: {
      "text-align": "center",
      "margin-block": "36px"
    }
  }, [_c('c-spinner', {
    attrs: {
      "thickness": "4px",
      "speed": "0.65s",
      "empty-color": "green.200",
      "color": "primary.400",
      "size": "xl"
    }
  }), _c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" Memuat data Profil Gizi... ")])], 1)], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.showModalConfirm,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px 20px 24px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400",
            "margin-bottom": "4px"
          }
        }, [_vm._v(" Sebelum keluar pastikan sudah menyimpan formulir ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "14px",
            "size-desktop": "16px",
            "color": "#555"
          }
        }, [_vm._v(" Data akan tersimpan di draft ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined",
            "is-disabled": _vm.isLoadingSave
          },
          on: {
            "click": _vm.onCloseModalConfirm
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingSave
          },
          on: {
            "click": _vm.onSubmitModalConfirm
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }