function getRoundedResult(number) {
  return (parseFloat(number) * 100 + 0.05) / 100
}

// q: why we use getRoundedResult(value).toFixed(2) instead of
//    parseFloat(value).toFixed(2) directly?
// a: because case 0.075 is converted to 0.07
//    so we need to round it to 0.08
export const parseFloatToFixed2 = (value) => {
  return parseFloat(getRoundedResult(value).toFixed(2))
}