import { bmiStatus } from '@/constants/meal-plans'
import { parseFloatToFixed2 } from '@/utils/other'

export const nutritionalStatusAdult = {
  calculateImt(weight, height) {
    height = height / 100
    const result = weight / (height * height)

    return this.getRoundedResult(parseFloat(result)).toFixed(2)
  },

  getBmiStatus(imt) {
    let result

    // Rule based on BMI value
    if (imt < 18.5) {
      result = bmiStatus.BERAT_BADAN_KURANG
    } else if (imt < 23) {
      result = bmiStatus.NORMAL
    } else if (imt < 25) {
      result = bmiStatus.PREOBESITAS
    } else if (imt < 30) {
      result = bmiStatus.OBESITAS_1
    } else {
      result = bmiStatus.OBESITAS_2
    }

    return result
  },

  getIdealWeightMinimum(height) {
    height = height / 100
    const minimum = parseFloat(18.5 * (height * height))
    return Math.round(minimum)
  },

  getIdealWeightMaximum(height) {
    height = height / 100
    const maximum = parseFloat(22.9 * (height * height))
    return Math.round(maximum)
  },

  getIdealWeightMedian(height) {
    const minimum = this.getIdealWeightMinimum(height)
    const maximum = this.getIdealWeightMaximum(height)

    const median = parseFloat(((minimum + maximum) / 2))

    return this.getRoundedResult(median).toFixed(2)
  },

  getBroccaIdealWeight(height, gender) {
    let result

    if (gender === 'female') {
      if (height < 150) {
        result = parseFloat((height - 100) * 1)
      } else {
        result = parseFloat(0.9 * (height - 100) * 1)
      }
    } else {
      if (height < 160) {
        result = parseFloat((height - 100) * 1)
      } else {
        result = parseFloat(0.9 * (height - 100) * 1)
      }
    }

    return this.getRoundedResult(parseFloat(result)).toFixed(2)
  },

  getIdealWeightAdjusted(weight, height, gender) {
    const broccaIdealWeight = parseFloat(this.getBroccaIdealWeight(height, gender))
    const result = this.getRoundedResult(((parseFloat(weight) - broccaIdealWeight) * 0.25) + broccaIdealWeight)

    return result.toFixed(2)
  },

  getRoundedResult(number) {
    return (parseFloat(number) * 100 + 0.05) / 100
  },
}

export const mifflinStJeorFormula = {
  bassEnergyRequirement(age, gender, weight, height) {
    let constants

    if (gender === 'female') {
      constants = -161
    } else {
      constants = 5
    }

    const result = parseFloat(10 * weight + 6.25 * height - 5 * age + constants)

    return Math.round(result)
  },

  totalEnergyNeeded(
    age,
    gender,
    weight,
    height,
    physicalActivity,
    maternalCondition,
    calorieDeficit,
  ) {
    const bassEnergyRequirement = this.bassEnergyRequirement(
      age,
      gender,
      weight,
      height,
    )

    physicalActivity = physicalActivity / 100

    const result =
      bassEnergyRequirement +
      bassEnergyRequirement * physicalActivity +
      maternalCondition -
      calorieDeficit

    return Math.round(result)
  },
}

export const perkeniFormula = {
  bassEnergyRequirement(gender, weight) {
    let constants

    if (gender === 'female') {
      constants = 25
    } else {
      constants = 30
    }

    const result = parseFloat(constants * weight)

    return Math.round(result)
  },

  totalEnergyNeeded(
    gender,
    weight,
    physicalActivity,
    ageFactor,
    metabolicStressFactor,
    malnutritionStatusFactor,
    overnutritionStatusFactor,
    maternalCondition,
  ) {
    physicalActivity = physicalActivity / 100
    ageFactor = ageFactor / 100
    metabolicStressFactor = metabolicStressFactor / 100
    malnutritionStatusFactor = malnutritionStatusFactor / 100
    overnutritionStatusFactor = overnutritionStatusFactor / 100

    const bassEnergyRequirement = this.bassEnergyRequirement(gender, weight)

    const result = parseFloat(
      bassEnergyRequirement +
      (physicalActivity -
        ageFactor +
        metabolicStressFactor +
        malnutritionStatusFactor -
        overnutritionStatusFactor) *
      bassEnergyRequirement +
      maternalCondition,
    )

    return Math.round(result)
  },
}

export const ckdFormula = {
  totalEnergyNeeded(weight, ckdEnergyRequirement) {
    const result = parseFloat(weight * ckdEnergyRequirement)

    return Math.round(result)
  },
}

export const nutritionalStatusChild = {
  calculateImt(weight, correctedHeight) {
    correctedHeight = correctedHeight / 100
    const result = weight / (correctedHeight * correctedHeight)

    return parseFloat(result).toFixed(2)
  },
}

export const totalEnergyChild = {
  totalEnergyNeeded(constants, weightIdeal) {
    const result = parseFloat(constants * weightIdeal)

    return Math.round(result)
  },

  totalEnergyNeeded2(age, type, gender, weight) {
    let constants

    if (type === 'month') {
      constants = age < 6 ? 108 : 98
    } else {
      if (age < 4) constants = 102
      else if (age < 7) constants = 90
      else if (age < 11) constants = 70
      else if (age < 15 && gender === 'female') constants = 47
      else if (age < 19 && gender === 'female') constants = 40
      else if (age < 15 && gender === 'male') constants = 55
      else if (age < 19 && gender === 'male') constants = 45
    }

    const result = parseFloat(constants * weight)

    return Math.round(result)
  },
}

export const energySufficiencyFormula = {
  energySufficiency(totalEnergy, energyIntake) {
    const result = parseFloat((energyIntake / totalEnergy) * 100)

    return Math.round(result)
  },

  energyStatus(totalEnergy, energyIntake) {
    const energySufficiency = this.energySufficiency(totalEnergy, energyIntake)
    let result

    if (energySufficiency < 80) result = 'Asupanmu kurang'
    else if (energySufficiency <= 110) result = 'Asupanmu cukup'
    else result = 'Asupanmu berlebih'

    return result
  },
}

export const proteinIntakeFormula = {
  proteinRequirement(weight, proteinPerWeight) {
    const result = parseFloat(weight * proteinPerWeight)

    return parseFloatToFixed2(result)
  },

  proteinRequirementConstant(weight, proteinPerWeight, totalEnergy) {
    const proteinRequirement = this.proteinRequirement(
      weight,
      proteinPerWeight,
    )

    const result = parseFloat((proteinRequirement * 4) / totalEnergy)

    return parseFloatToFixed2(result)
  },

  proteinAdequacy(weight, proteinPerWeight, currentProtein) {
    const proteinRequirement = this.proteinRequirement(
      weight,
      proteinPerWeight,
    )

    const result = parseFloat((currentProtein / proteinRequirement) * 100)

    return parseFloatToFixed2(result)
  },

  proteinStatus(weight, proteinPerWeight, currentProtein) {
    const proteinAdequacy = this.proteinAdequacy(
      weight,
      proteinPerWeight,
      currentProtein,
    )

    let result

    if (proteinAdequacy < 80) result = 'Asupanmu kurang'
    else if (proteinAdequacy <= 110) result = 'Asupanmu cukup'
    else result = 'Asupanmu berlebih'

    return result
  },
}

export const fatIntakeFormula = {
  fatRequirement(totalEnergy, fatRequirement) {
    fatRequirement = fatRequirement / 100

    const result = parseFloat(totalEnergy * fatRequirement) / 9

    return Math.round(result)
  },

  fatAdequacy(totalEnergy, fatRequirement, currentFat) {
    const fatRequirementFormula = this.fatRequirement(
      totalEnergy,
      fatRequirement,
    )
    const result = parseFloat((currentFat / fatRequirementFormula) * 100)

    return Math.round(result)
  },

  fatStatus(totalEnergy, fatRequirement, currentFat) {
    const fatAdequacy = this.fatAdequacy(
      totalEnergy,
      fatRequirement,
      currentFat,
    )

    let result

    if (fatAdequacy < 80) result = 'Asupanmu kurang'
    else if (fatAdequacy <= 110) result = 'Asupanmu cukup'
    else result = 'Asupanmu berlebih'

    return result
  },
}

export const carbohydrateIntakeFormula = {
  carbohydrateRequirement(totalEnergy, proteinConstant, sufficientFat) {
    sufficientFat = sufficientFat / 100

    const result = parseFloat(
      (totalEnergy * (1 - proteinConstant - sufficientFat / 1)) / 4,
    )

    return Math.round(result)
  },

  carbohydrateAdequacy(
    totalEnergy,
    proteinConstant,
    sufficientFat,
    currentCarbohydrate,
  ) {
    const carbohydrateRequirement = this.carbohydrateRequirement(
      totalEnergy,
      proteinConstant,
      sufficientFat,
    )

    const result = parseFloat(
      (currentCarbohydrate / carbohydrateRequirement) * 100,
    )

    return Math.round(result)
  },

  carbohydrateStatus(
    totalEnergy,
    proteinConstant,
    sufficientFat,
    currentCarbohydrate,
  ) {
    const carbohydrateAdequacy = this.carbohydrateAdequacy(
      totalEnergy,
      proteinConstant,
      sufficientFat,
      currentCarbohydrate,
    )

    let result

    if (carbohydrateAdequacy < 80) result = 'Asupanmu kurang'
    else if (carbohydrateAdequacy <= 110) result = 'Asupanmu cukup'
    else result = 'Asupanmu berlebih'

    return result
  },
}

export const fiberIntakeFormula = {
  fiberAdequacy(fiberRequirement, currentFiber) {
    const result = parseFloat((currentFiber / fiberRequirement) * 100)

    return Math.round(result)
  },

  fiberStatus(fiberRequirement, currentFiber) {
    const fiberAdequacy = this.fiberAdequacy(fiberRequirement, currentFiber)

    let result

    if (fiberAdequacy < 80) result = 'Asupanmu kurang'
    else if (fiberAdequacy <= 110) result = 'Asupanmu cukup'
    else result = 'Asupanmu berlebih'

    return result
  },
}