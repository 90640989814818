var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-accordion-item', {
    attrs: {
      "border": "none",
      "is-open": _vm.isOpen
    },
    on: {
      "change": _vm.toggleAccordion
    }
  }, [_c('c-accordion-header', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        _hover: {
          background: _vm.isError ? '#f9cebf' : '#9be8c7'
        }
      },
      expression: "{\n      _hover: {\n        background: isError ? '#f9cebf' : '#9be8c7',\n      },\n    }"
    }],
    attrs: {
      "rounded": "8px",
      "background-color": _vm.isError ? '#FCDED3' : '#C7F9E3',
      "h": ['48px', '62px']
    }
  }, [_c('c-box', {
    attrs: {
      "flex": "1",
      "text-align": "left",
      "color": _vm.isError ? '#D32737' : '#008C81',
      "font-family": "Roboto, sans-serif",
      "font-weight": "500",
      "font-size": ['14px', '18px']
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('c-accordion-icon', {
    attrs: {
      "size": "24px",
      "name": "chevron-down"
    }
  })], 1), _c('c-accordion-panel', {
    attrs: {
      "pb": "2"
    }
  }, [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }