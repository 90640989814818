<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :mb="isWithoutMarginBottom ? '0' : ['12px', '16px']"
    :is-read-only="isReadOnly"
    :w="fullWidth ? '100%' : 'inherit'"
  >
    <c-form-label
      v-show="!isWithoutLabel"
      :font-size="['14px', '16px']"
      :color="isDisabled ? '#888' : (isInvalid ? '#D32737' : (value ? '#008C81' : '#555'))"
      font-weight="400"
      font-family="Roboto"
      :opacity="isDisabled ? '1' : ''"
    >
      {{ label }}
      <c-box
        v-if="!isRequired"
        as="span"
        font-family="Roboto"
        font-weight="400"
        color="#C4C4C4"
        :font-size="['12px', '14px']"
      >
        (Optional)
      </c-box>
    </c-form-label>
    <c-textarea 
      v-if="type == 'textarea'" 
      v-model="modelValue"
      :height="['48px', '62px']"
      :font-size="['14px', '18px']"
      :color="value ? '#008C81' : '#555'"
      :font-weight="value ? '500' : '400'"
      :placeholder="placeholder ? placeholder : label"
      border-radius="8px"
      @blur="onBlur"
    />
    <c-box
      v-else-if="type === 'date'"
      style="position: relative;"
      :height="['48px', '62px']"
      border-radius="8px"
    >
      <DatePicker
        v-model="modelValue"
        :input-class="{
          default: true,
          err: isInvalid,
        }"
        value-type="YYYY-MM-DD"
        format="DD MMMM YYYY"
        type="date"
        :placeholder="placeholder"
        :clearable="false"
        :disabled="isDisabled"
        editable="false"
        lang="id"
        :disabled-date="(date) => date > new Date()"
        @blur="onBlur"
      >
        <template #icon-calendar>
          &nbsp;
        </template>
      </DatePicker>
    </c-box>
    <c-input-group 
      v-else
    >
      <c-input-left-element
        v-if="leftElement || iconLeftElement"
        :height="['48px', '62px']"
        :font-size="['14px', '18px']"
        :color="value ? '#008C81' : '#555'"
        font-weight="500"
        h="100%"
      >
        <c-flex
          h="100%"
          :border-left-width="leftElement ? '3px' : '0px'"
          :border-color="isDisabled ? '#888' : (isInvalid ? '#e66673' : (value ? '#008C81' : '#555'))"
          :color="isDisabled ? '#888' : (value ? '#008C81' : '#555')"
          justify-content="center"
          align-items="center"
          px="8px"
        >
          {{ iconLeftElement ? "" : leftElement }}
          <c-box
            v-if="iconLeftElement"
            :h="['25px', '30px']"
            :w="['25px', '30px']"
            :min-h="['25px', '30px']"
            :min-w="['25px', '30px']"
          >
            <inline-svg
              :src="iconLeftElement"
              height="100%"
              width="100%"
              :fill="iconLeftElementColor"
            />
          </c-box>
        </c-flex>
      </c-input-left-element>
      <c-input
        v-model="modelValue"
        :type="type ? type : 'text'"
        :height="['48px', '62px']"
        :font-size="['14px', '18px']"
        :color="value ? (isInvalid ? '#D32737' : '#008C81') : '#555'"
        :font-weight="value ? '500' : '400'"
        :placeholder="placeholder ? placeholder : label"
        border-radius="8px"
        @blur="onBlur"
      />
      <c-input-right-element
        v-if="rightElement || iconRightElement"
        :height="['48px', '62px']"
        margin="0"
        h="100%"
        :color="value ? '#008C81' : '#555'"
        width="auto"
        class="inline-table"
      >
        <c-flex
          height="100%"
          align-items="center"
        > 
          <c-flex
            v-if="iconRightElement"
            h="100%"
            align-items="center"
            px="16px"
            :cursor="iconRightElement ? 'pointer' : 'auto'"
            @click="onClickRightElement"
          >
            <c-text
              v-if="iconRightElement"
              as="span"
              :h="['25px', '30px']"
              :w="['25px', '30px']"
              :min-h="['25px', '30px']"
              :min-w="['25px', '30px']"
            >
              <inline-svg
                v-if="iconRightElement"
                :src="iconRightElement"
                height="100%"
                width="100%"
                :fill="iconRightElementColor"
              />
            </c-text>
          </c-flex>
          <c-flex
            v-if="rightElement"
            h="100%"
            :border-left-width="rightElement ? '3px' : '0px'"
            :border-color="isDisabled ? '#888' : (isInvalid ? '#e66673' : '#888')"
            :font-size="['14px', '18px']"
            font-weight="500"
            :color="isDisabled ? '#888' : (isInvalid ? '#e66673' : 'primary.400')"
            justify-content="center"
            align-items="center"
            px="16px"
          >
            {{ rightElement }}
          </c-flex>
        </c-flex>
      </c-input-right-element>
    </c-input-group>
    <c-form-helper-text
      v-if="isHelper"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      d="flex"
      gap="8px"
      align-items="center"
      :color="helperTextColor"
    >
      <c-box
        display="flex"
        gap="8px"
        align-items="center"
        :cursor="hasHelperTextClick ? 'pointer' : 'auto'"
        @click="$emit('helper-text-click')"
      >
        <c-box
          v-if="helperTextLogo"
          :min-height="['13px', '16px']"
          :min-width="['13px', '16px']"
          :height="['13px', '16px']"
          :width="['13px', '16px']"
        >
          <inline-svg
            :src="helperTextLogo"
            height="100%"
            width="100%"
            :fill="helperTextColor"
          />
        </c-box>
        {{ helperText }}
      </c-box>
    </c-form-helper-text>

    <c-form-helper-text
      v-if="!isDataEmpty(invalidText) && isInvalid"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      d="flex"
      flex-direction="column"
      gap="8px"
      color="#D32737"
    >
      <c-text
        v-for="(invalidTextValue, invalidTextKey) in invalidText_"
        :key="invalidTextKey"
        d="flex"
        align-items="center"
        gap="8px"
      >
        <c-box
          :min-height="['13px', '16px']"
          :min-width="['13px', '16px']"
          :height="['13px', '16px']"
          :width="['13px', '16px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-circle-warn.svg')"
            height="100%"
            width="100%"
            fill="#D32737"
          />
        </c-box>
        {{ invalidTextValue }}
      </c-text>
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { formatDateV2 } from '@/utils/format-date'
import { isDataEmpty } from '@/utils/is-data-empty'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/id'
import 'vue2-datepicker/index.css'

export default {
  name: 'FormInput2',
  components: {
    DatePicker,
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      required: true,
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
    leftElement: {
      type: String,
      default: '',
    },
    rightElement: {
      type: String,
      default: '',
    },
    iconLeftElement: {
      type: String,
      default: '',
    },
    iconLeftElementColor: {
      type: String,
      default: 'black',
    },
    iconRightElement: {
      type: String,
      default: '',
    },
    iconRightElementColor: {
      type: String,
      default: 'black',
    },
    isWithoutMarginBottom: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    helperTextType: {
      type: String,
      default: '',
    },
    helperTextColor: {
      type: String,
      default: '',
    },
    isWithoutLabel: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'right-element',
  ],
  computed: {
    invalidText_() {
      if (typeof this.invalidText === 'string') {
        return [this.invalidText]
      }
      return this.invalidText
    },
    helperTextLogo() {
      if (this.helperTextType === 'warn') {
        return require('@/assets/icons/icon-circle-warn.svg')
      }
      if (this.helperTextType === 'questionmark') {
        return require('@/assets/icons/icon-circle-questionmark.svg')
      }
      if (this.helperTextType === 'info') {
        return require('@/assets/icons/icon-info-2.svg')
      }
      return ''
    },
    hasHelperTextClick() {
      return this.$listeners['helper-text-click']
    },
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('trigger-change', val)
        if (this.type == 'textarea') {
          this.$emit('blur')
        }
      },
    },
  },
  methods: {
    isDataEmpty,
    formatDateV2,
    onBlur() {
      this.$emit('blur')
    },
    onClickRightElement() {
      this.$emit('right-element')
    },
  },
}
</script>

<style scoped>
::v-deep .default {
  cursor: pointer;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  border: 1px solid rgba(136, 136, 136, 0.2);
  height: 100%;
  width: 100%;
  border-radius: 8px;
  padding: 0 20px;
  color: #008C81;
}

@media (min-width: 768px) {
  ::v-deep .default {
    font-size: 18px;
  }
}

::v-deep .default:focus-visible {
  outline: none;
}

::v-deep .err {
  outline: 2px solid #e66673;
  color: #D32737;
}

::v-deep .err:focus-visible {
  outline: 2px solid #e66673;
}

::v-deep input,
::v-deep textarea{
  border-color: #888;
  box-shadow: 0 0 0 1px #888;
}

::v-deep input[disabled],
::v-deep textarea[disabled]{
  opacity: 1 !important;
  background-color: #F2F2F2 !important;
  color: #888 !important;
  border-color: #888 !important;
  box-shadow: 0 0 0 1px #888 !important;
  cursor: not-allowed;
}

::v-deep .disabled .mx-icon-calendar {
  cursor: not-allowed;
}

::v-deep .mx-datepicker, ::v-deep .mx-input-wrapper {
  width: 100%;
  height: 100%;
}

::v-deep .inline-table {
  display: inline-table !important;
}
</style>
