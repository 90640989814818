<template>
  <c-box
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot
      :push="push"
      :remove="remove"
    />
  </c-box>
</template>

<script>
export default {
  name: 'TestFieldArray',
  methods: {
    push(arr, item) {
      arr.push(item)
    },
    remove(arr, index) {
      arr.splice(index, 1)
    },
  },
}
</script>
