<template>
  <c-box>
    <c-flex
      align-items="center"
      gap="16px"
    >
      <c-button
        variant="ghost"
        width="30px"
        min-width="30px"
        height="30px"
        margin="0"
        padding="0"
        @click="isOpenModalBackToPreview = true"
      >
        <c-box
          :width="['12px', '15px']"
          :height="['12px', '15px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-arrow-down.svg')"
            height="100%"
            width="100%"
            fill="#008C81"
            style="transform: rotate(90deg);"
          />
        </c-box>
      </c-button>
      <c-text
        color="neutral.superDarkGray"
        font-size="22px"
        font-weight="600"
      >
        Kondisi Tubuh & Gaya Hidup
      </c-text>
    </c-flex>
    <c-flex
      justify-content="flex-end"
      gap="16px"
      margin-bottom="16px"
    >
      <c-button
        :variant="buttonSave.variant"
        border-radius="40px"
        variant-color="primary"
        :is-disabled="isSaveDisabled"
        :is-loading="isLoadingSave"
        @click="onSaveDraft"
      >
        {{ buttonSave.label }}
      </c-button>
    </c-flex>

    <NutritionalProfileSteps
      :draft="draft"
      :body-conditions="!isDisabledSubmit ? 1 : -1"
    />

    <c-box>
      <c-box
        v-for="(item, index) in bodyConditions"
        :key="index"
        position="relative"
        display="flex"
        gap="16px"
        padding-bottom="24px"
      >
        <c-box
          flex-grow="1"
        >
          <FormValidationBodyCondition
            ref="formValidationBodyCondition"
            v-model="bodyConditions[index]"
            :index="index"
            :current-condition-options="currentConditionOptions"
            :standard-value-options="standardValueOptions"
            :conclusion-options="conclusionOptions"
            :indicator-options="indicatorOptions"
          />
        </c-box>
        <c-box
          v-if="index > 1"
          flex-shrink="0"
        >
          <c-button
            margin-top="38px"
            padding="6px"
            background-color="transparent"
            @click="removeBodyCondition(index)"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-trash.svg')"
              height="30px"
              width="30px"
              fill="#D32737"
            />
          </c-button>
        </c-box>
      </c-box>
    
      <c-box
        width="100%"
        margin-bottom="24px"
        text-align="center"
      >
        <!-- :is-disabled="isEmptyBodyCondition" -->
        <c-button
          variant-color="primary"
          variant="outline"
          border-radius="60px"
          h="60px"
          w="70%"
          right-icon="add"
          gap="8px"
          @click="addBodyCondition"
        >
          Tambah Indikator Lainnya
        </c-button>
      </c-box>

      <c-grid
        w="100%"
        :template-columns="['repeat(2, 1fr)']"
        :gap="['16px']"
      >
        <c-button
          variant="outline"
          variant-color="primary"
          height="60px"
          border-radius="60px"
          font-size="18px"
          :is-disabled="isLoadingSave"
          @click="onOpenModalCancel"
        >
          Sebelumnya
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          height="60px"
          border-radius="60px"
          font-size="18px"
          :is-disabled="isLoadingSave"
          :is-loading="isLoadingSave"
          @click="handleNext"
        >
          Selanjutnya
        </c-button>
      </c-grid>

      <BaseModal
        :is-open="!!isOpenModalCancel"
        :close-on-overlay-click="false"
        :with-button-close="false"
      >
        <template #header>
          <c-box
            display="flex"
            justify-content="center"
            margin="24px 0 0 0"
          >
            <c-image
              height="120px"
              object-fit="cover"
              :src="require('@/assets/images/image-question.svg')"
              alt="image question"
            />
          </c-box>
        </template>
        <template #body>
          <c-box
            justify-content="center"
            padding="30px 16px 20px 16px"
            text-align="center"
          >
            <BaseText
              size-mobile="16px"
              size-desktop="18px"
              color="primary.400"
              margin-bottom="8px"
            >
              Data kamu belum lengkap
            </BaseText>
            <BaseText
              size-mobile="12px"
              size-desktop="14px"
              color="neutral.darkGray"
            >
              Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman sebelumnya?
            </BaseText>
          </c-box>
        </template>
        <template #footer>
          <c-flex
            gap="20px"
            width="100%"
            padding-left="24px"
            padding-right="24px"
            margin-bottom="24px"
          >
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="onCloseModalCancel"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              @click="onConfirmModalCancel"
            >
              Ya
            </BaseButton>
          </c-flex>
        </template>
      </BaseModal>
    </c-box>

    <BaseModal
      :is-open="!!isOpenModalNext"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="image question"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          justify-content="center"
          padding="30px 16px 20px 16px"
          text-align="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
            margin-bottom="8px"
          >
            Data kamu belum lengkap
          </BaseText>
          <BaseText
            size-mobile="12px"
            size-desktop="14px"
            color="neutral.darkGray"
          >
            Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman selanjutnya?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            @click="onCancelNext"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="onNext"
          >
            Ya
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <!-- MODAL -->
    <ModalSuccessSaveData
      :is-open="isOpenModalSuccesSaveData"
      @close="isOpenModalSuccesSaveData = false"
    />

    <!-- Modal back to preview -->
    <BaseModal
      :is-open="isOpenModalBackToPreview"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="image question"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          justify-content="center"
          padding="30px 24px 20px 24px"
          text-align="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
          >
            Apakah anda yakin<br>ingin membatalkan pengisian formulir?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            @click="isOpenModalBackToPreview = false"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="handleBackToPreview"
          >
            Konfirmasi
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import _ from 'lodash'
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import NutritionalProfileSteps from '@/components/meal-plan/_widgets/steps.vue'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import {
  tabs,
} from '@/constants/meal-plans'
import FormValidationBodyCondition from '@/components/meal-plan/_widgets/form-validation-body-condition.vue'
import { isAllFormValid } from '@/components/meal-plan/_utils'
import ModalSuccessSaveData
  from '@/components/meal-plan/_widgets/modal-success-save-data.vue'
import { CBox, CButton, CFlex, CImage } from '@chakra-ui/vue'

export default {
  components: {
    CButton,
    CFlex,
    CBox, CImage,
    ModalSuccessSaveData,
    BaseModal,
    BaseText,
    BaseButton,
    NutritionalProfileSteps,
    FormValidationBodyCondition,
  },
  props: {
    draft: {
      type: [Object, null],
      default: null,
    },
    isDraftExist: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:validation'],
  data() {
    return {
      isOpenModalBackToPreview: false,
      isOpenModalNext: false,
      isOpenModalSuccesSaveData: false,
      formDuplicate: [],
      bodyConditions: [
        {
          indicator: null,
          indicatorOther: null,
          currentCondition: null,
          currentConditionUnit: null,
          currentConditionUnitOther: null,
          standardValue: null,
          standardValueUnit: null,
          standardValueUnitOther: null,
          conclusion: null,
          conclusionOther: null,
        },
        {
          indicator: null,
          indicatorOther: null,
          currentCondition: null,
          currentConditionUnit: null,
          currentConditionUnitOther: null,
          standardValue: null,
          standardValueUnit: null,
          standardValueUnitOther: null,
          conclusion: null,
          conclusionOther: null,
        },
      ],
      indicatorOptions: [],
      currentConditionOptions: [],
      standardValueOptions: [],
      conclusionOptions: [],
      
      isLoadingData: true,
      isLoadingSave: false,
      isOpenModalCancel: false,
    }
  },
  validations: {
    bodyConditions: {
      required,
      $each: {
        indicator: {
          required,
        },
        indicatorOther: {
          required: requiredIf((model) => model.indicator === 'Lainnya'),
          maxLength: maxLength(20),
        },
        currentCondition: {
          required,
        },
        currentConditionUnit: {
          required,
        },
        currentConditionUnitOther: {
          required: requiredIf((model) => model.currentConditionUnit === 'Lainnya'),
          maxLength: maxLength(20),
        },
        standardValue: {
          required,
        },
        standardValueUnit: {
          required,
        },
        standardValueUnitOther: {
          required: requiredIf((model) => model.standardValueUnit === 'Lainnya'),
          maxLength: maxLength(20),
        },
        conclusion: {
          required,
        },
        conclusionOther: {
          required: requiredIf((model) => model.conclusion === 'Lainnya'),
          maxLength: maxLength(20),
        },
      },
    },
  },
  computed: {
    isSaveDisabled() {
      if (!this.hasChanges) {
        return true
      }
      return this.isLoadingSave
    },
    buttonSave() {
      const isAllValid = isAllFormValid({
        nutritionalConditions: this.draft?.nutritionalConditions?.validation === 1,
        nutritionalIntake: this.draft?.nutritionalIntake?.validation === 1,
        bodyConditions: !this.$v?.$invalid,
        targetAndSuggestions: this.draft?.targetAndSuggestions?.validation === 1,
      })
      return isAllValid ? {
        label: 'Simpan',
        variant: 'solid',
      } : {
        label: 'Simpan Draf',
        variant: 'outline',
      }
    },
    hasChanges() {
      return !_.isEqual(this.bodyConditions, this.formDuplicate)
    },
    constants() {
      return {
        tabs,
      }
    },
    isSectionKebutuhanTubuhValid() {
      return !this.$v.$invalid
    },
    isDisabledSubmit() {
      return this.$v.$invalid
    },
    isEmptyBodyCondition() {
      let emptyObj = this.bodyConditions.findIndex(
        (dt) => !dt.indicator || !dt.currentCondition || !dt.standardValue || !dt.currentConditionUnit || !dt.standardValueUnit || !dt.conclusion,
      )
      if (emptyObj > -1) return true
      return false
    },
  },
  watch: {
    form: {
      handler() {
        const data = {
          form: 'body-condition',
          validation: this.$v.form.$invalid ? -1 : 1,
        }
        this.$emit('update:validation', data)
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getFormOptBodyConditions()
    if (this.draft?.bodyConditions?.data && !_.isEmpty(this.draft?.bodyConditions?.data)) {
      this.bodyConditions = this.draft?.bodyConditions?.data.map((it) => {
        const isOtherIndicator = it?.indicator ? !this.indicatorOptions.some((el) => el?.value === it?.indicator) : false
        
        const isOtherCurrentConditionUnit = it?.currentConditionUnit ? !this.currentConditionOptions.some((el) => el?.value === it?.currentConditionUnit) : false
        
        const isOtherStandardValueUnit = it?.standardValueUnit ? !this.standardValueOptions.some((el) => el?.value === it?.standardValueUnit) : false
        
        const isOtherConclusionOptions = it?.conclusion ? !this.conclusionOptions.some((el) => el?.value === it?.conclusion) : false

        return {
          indicator: isOtherIndicator ? 'Lainnya' : it?.indicator,
          indicatorOther: isOtherIndicator ? it?.indicator : null,
          currentCondition: it?.currentCondition ?? null,
          currentConditionUnit: isOtherCurrentConditionUnit ? 'Lainnya' : it?.currentConditionUnit,
          currentConditionUnitOther: isOtherCurrentConditionUnit ? it?.currentConditionUnit : null,
          standardValue: it?.standardValue ?? null,
          standardValueUnit: isOtherStandardValueUnit ? 'Lainnya' : it?.standardValueUnit,
          standardValueUnitOther: isOtherStandardValueUnit ? it?.standardValueUnit : null,
          conclusion: isOtherConclusionOptions ? 'Lainnya' : it?.conclusion,
          conclusionOther: isOtherConclusionOptions ? it?.conclusion : null,
        }
      })

      setTimeout(() => {
        this.onFormTouch()
      }, 100)
    }

    setTimeout(() => {
      this.isLoadingData = false
    }, 500)
    
    this.formDuplicate = _.cloneDeep(this.bodyConditions)
  },
  methods: {
    isAllFormValid,
    handleBackToPreview() {
      this.isOpenModalBackToPreview = false
      this.onSaveDraft()
      this.$router.replace({
        name: 'nutri.meal-plan.profile.detail',
        params: {
          ...this.$route.params,
          bypass: 1,
        },
      })
    },
    async getFormOptBodyConditions() {
      try {
        const res = await reqNutriMealPlan.getFormOptBodyConditions(this.$store.getters.axios)
        if (res.data?.data?.length > 0) {
          res.data?.data.forEach((it) => {
            if (it?.params === 'Satuan kondisi saat ini') {
              this.currentConditionOptions = it?.options
            } else if (it?.params === 'Satuan nilai standard') {
              this.standardValueOptions = it?.options
            } else if (it?.params === 'Kesimpulan') {
              this.conclusionOptions = it?.options
            } else if (it?.params === 'Indikator') {
              this.indicatorOptions = it?.options
            }
          })
        }
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.response?.data?.message || 'Something went wrong!',
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
        })
      }
    },
    addBodyCondition() {
      this.bodyConditions.push({
        indicator: null,
        indicatorOther: null,
        currentCondition: null,
        currentConditionUnit: null,
        currentConditionUnitOther: null,
        standardValue: null,
        standardValueUnit: null,
        standardValueUnitOther: null,
        conclusion: null,
        conclusionOther: null,
      })
    },
    removeBodyCondition(index) {
      this.bodyConditions.splice(index, 1)
    },
    handleNext() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.isOpenModalNext = true
        return
      }
      this.onNext()
    },
    onCancelNext() {
      this.isOpenModalNext = false
    },
    async onNext() {
      try {
        await this.onSaveDraft()
        this.$router.replace({
          name: 'nutri.meal-plan.profile.edit',
          params: this.$route.params,
          query: {
            tab: this.constants.tabs['target-and-suggestion'],
          },
        })
      } catch (e) {
        //
      }
    },
    async onSaveDraft() {
      try {
        this.isLoadingSave = true
        this.onFormTouch()

        let bodyConditions = this.bodyConditions.map((it) => {
          return {
            indicator: it?.indicator === 'Lainnya' ? it?.indicatorOther : it?.indicator,
            currentCondition: it?.currentCondition,
            currentConditionUnit: it?.currentConditionUnit === 'Lainnya' ? it?.currentConditionUnitOther : it?.currentConditionUnit,
            standardValue: it?.standardValue,
            standardValueUnit: it?.standardValueUnit === 'Lainnya' ? it?.standardValueUnitOther : it?.standardValueUnit,
            conclusion: it?.conclusion === 'Lainnya' ? it?.conclusionOther : it?.conclusion,
          }
        })
        
        let payload = {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: Number(this.$route.params.month),
          bodyConditions: {
            data: bodyConditions,
            validation: this.$v.$invalid ? -1 : 1,
          },
        }

        await reqNutriMealPlan.updateMealPlanDraft(this.$store.getters.axios, { payload })

        if (isAllFormValid({
          nutritionalConditions: this.draft?.nutritionalConditions?.validation === 1,
          nutritionalIntake: this.draft?.nutritionalIntake?.validation === 1,
          bodyConditions: !this.$v.$invalid,
          targetAndSuggestions: this.draft?.targetAndSuggestions?.validation === 1,
        })) {
          this.isOpenModalSuccesSaveData = true
        } else {
          this.$toast({
            title: 'Success',
            description: 'Successfully save to draft',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
          })
        }
        this.formDuplicate = _.cloneDeep(this.bodyConditions)
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.response?.data?.message || 'Something went wrong!',
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
        })
      } finally {
        this.isLoadingSave = false
      }
    },
    onOpenModalCancel() {
      if (!this.isLoadingSave) {
        this.isOpenModalCancel = true
      } 
    },
    onCloseModalCancel() {
      this.isOpenModalCancel = false
    },
    onConfirmModalCancel() {
      this.onCloseModalCancel()
      this.onSaveDraft()
      this.$router.replace({
        name: 'nutri.meal-plan.profile.edit',
        params: this.$route.params,
        query: {
          tab: this.constants.tabs['nutritional-intake'],
        },
      })
    },
    onFormTouch() {
      let forms = []
      this.$refs.formValidationBodyCondition.forEach((it) => {
        forms.push(it)
      })

      if (forms.length > 0) {
        forms.forEach((it) => {
          it.onFormTouch()
        })
      }
    },
  },
}
</script>
